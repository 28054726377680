module.exports = [{
      plugin: require('/Users/kirstenlambertsen/Documents/GATSBY/SITES/xapnik-slack/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["PT Serif: 400, 700"]},"custom":{"families":["Inter"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('/Users/kirstenlambertsen/Documents/GATSBY/SITES/xapnik-slack/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-SX0H1JLDEE"],"gtagConfig":{"optimize_id":"OPT_CONTAINER_ID","anonymize_ip":true,"cookie_expires":0}},
    },{
      plugin: require('/Users/kirstenlambertsen/Documents/GATSBY/SITES/xapnik-slack/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
